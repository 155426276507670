import type * as pt from "@prismicio/types"
import { CmsGuideLayout } from "../../../components/CmsGuideLayout"
import { lorem } from "../../../lib/lorem"
import { PageDataBodyFaq } from "../../../slices/PageDataBodyFaq"

type Question = React.ComponentPropsWithoutRef<
	typeof PageDataBodyFaq
>["questions"][number]

const question = (data: Partial<Question> = {}): Question => {
	return {
		question: lorem.question(),
		answer: [
			{ type: "paragraph", spans: [], text: lorem.sentences(2) },
		] as pt.RichTextField,
		open: false,
		...data,
	}
}

const Component = () => {
	return (
		<CmsGuideLayout>
			<PageDataBodyFaq
				questions={[question(), question(), question()]}
				heading={lorem.words(4)}
				link="#"
				linkText="Read All"
			/>
		</CmsGuideLayout>
	)
}

export default Component
